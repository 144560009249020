window.csdl = (window.csdl || {});

/**
 * Utilities for interacting with the menu.
 * @class Menu
 * @static
 */
csdl.Menu = (function Menu($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * jQuery elements.
   * @private
   */
  var $menu = $('.header');
  var $burger = $('.toolbar__burger');
  var $layout = $('.header__layout');
  var $close = $('.header__closemenu');
  var $menuToggler = $('[action-toggle-menu]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Initialize menu
    initMenu();

    // Return success
    return true;

  };

  /**
   * Initialize menu.
   * @private
   */
  var initMenu = function() {

    console.log('[Init menu.js]')

    // Open menu
    $burger.on('click', function(e) {

      e.preventDefault();

      $menu.addClass('header--open');
      $layout.fadeIn();
      $('body').css('overflow', 'hidden');

    });

    // Close menu
    $close.on('click', function (e) {

      e.preventDefault();

      $menu.removeClass('header--open');
      $layout.fadeOut();
      $('body').css('overflow', 'auto');

    });

    $layout.on('click', function (e) {

      e.preventDefault();

      $menu.removeClass('header--open');
      $layout.fadeOut();
      $('body').css('overflow', 'auto');

    });

    console.log($menuToggler.length);

    $menuToggler.on('click', function (e) {
      e.preventDefault();

      console.log('toggling');

      var $parent = $(this).parents('.has-menu');

      console.log($parent);

      if($parent.hasClass('menu__firstlevel--active')) {
        $parent.removeClass('menu__firstlevel--active');
        $parent.children('ul').slideUp();
      } else {
        $parent.addClass('menu__firstlevel--active');
        $parent.children('ul').slideDown();
      }
    });

  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init
  };

})(jQuery);
