window.csdl = (window.csdl || {});

/**
 * Utilities for sliders.
 * @class Slider
 * @static
 */
csdl.Slider = (function Slider($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Collection of jQuery elements.
   * @private
   */
  var $mainCarousel = $('.main-carousel');
  var $articleCarousel = $('.articles-carousel');
  var $calendarCarousel = $('.calendar-carousel');
  var $galleryCarousel = $('.gallery-carousel');

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Initialize carousels
    if ($mainCarousel.length) {
      initMain();
    }

    if ($articleCarousel.length) {
      initArticle();
    }

    if ($calendarCarousel.length) {
      initCalendar();
    }

    if ($galleryCarousel.length) {
      initGallery();
    }

    // Return success
    return true;

  };

  var initMain = function() {

    if ($(window).width() < 768) {

      $mainCarousel.flickity({
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: false
      });

    }

    return true;

  };

  /**
   * Initialize article carousel.
   * @private
   */
  var initArticle = function() {

    $articleCarousel.flickity({
      cellAlign: 'left',
      contain: true,
      prevNextButtons: true,
      pageDots: false,
      draggable: false
    });

    return true;

  };

  /**
   * Initialize gallery carousel.
   * @private
   */
  var initGallery = function() {

    if ($(window).width() < 768) {

      $galleryCarousel.flickity({
        cellAlign: 'left',
        contain: true,
        prevNextButtons: true,
        pageDots: false
      });

    }

    return true;

  };

  /**
   * Initialize calendar carousel.
   * @private
   */
  var initCalendar = function() {

    // Calendar carousel
    if ($(window).width() < 768) {

      var carousel = $calendarCarousel.flickity({
        cellAlign: 'center',
        contain: true,
        prevNextButtons: false,
        pageDots: false
      });

      var flickity = carousel.data('flickity');

      carousel.on('select.flickity', function() {

        var $selected = $('.calendar__buttons__button[data-destination=' + flickity.selectedIndex + ']');
        var $buttons = $('.calendar__buttons__button');
        var activeClass = 'calendar__buttons__button--active';

        $buttons.removeClass( activeClass );
        $selected.addClass( activeClass );

      });

    } else {

      $calendarCarousel.flickity({
        cellAlign: 'center',
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        draggable: false
      });

    }

    // Calendar buttons
    $('.calendar__buttons__button').click(function() {

      var activeClass = 'calendar__buttons__button--active';

      if (!$(this).hasClass(activeClass)) {

        var index = $(this).data('destination');
        var $buttons = $('.calendar__buttons__button');

        $calendarCarousel.flickity('select', index);

        $buttons.removeClass( activeClass );
        $(this).addClass( activeClass );

      }

    });

    return true;

  };

  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
