window.csdl = (window.csdl || {});

/**
 * Utilities for modals.
 * @class Modal
 * @static
 */
csdl.Modal = (function Modal($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Initialize photo galleries
    initGallery();

    // Return success
    return true;

  };

  /**
   * Initialize photo galleries.
   * @private
   */
  var initGallery = function() {

    var $galleries = $('.gallery');
    if (window.innerWidth < 768) {
      $galleries = $('.gallery .flickity-slider');
    }

    if ($galleries.length > 0) {
      $galleries.lightGallery();
      return true;
    }

    return false;

  };

  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);
