window.csdl = (window.csdl || {});

/**
 * Utilities for interacting with the application.
 * @class App
 * @static
 */
csdl.App = (function App($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param {Object} config.env      Current server environment
   * @param {Object} config.csrf     Token to submit form through ajax
   * @param {Object} config.locale   Current locale short code
   * @param {Object} config.device   Device detection based on browser signature
   * @param {Object} config.preview  Page is shown through live preview mode
   * @param {Object} config.datetime Current server datetime
   */
  var config = {
    env: 'production',
    csrf: null,
    locale: 'fr',
    device: 'desktop',
    preview: false,
    datetime: null,
    school: {
      title: 'Commission scolaire de Laval',
      color_1: '#173C68',
      color_2: '#FF9100',
      address: '955 Boulevard Saint-Martin O',
      city: 'Laval',
      postal: 'H7S 1M5',
      coordinates: {
        lat: 45.60665,
        lng: -73.71241,
      },
    },
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function(options) {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.datetime) { config.datetime = options.datetime; }

    if (options.school) {

      if (options.school.title) { config.school.title = options.school.title; }
      if (options.school.color_1) { config.school.color_1 = options.school.color_1; }
      if (options.school.color_2) { config.school.color_2 = options.school.color_2; }
      if (options.school.address) { config.school.address = options.school.address; }
      if (options.school.city) { config.school.city = options.school.city; }
      if (options.school.postal) { config.school.postal = options.school.postal; }

      if (options.school.coordinates) {

        if (options.school.coordinates.lat) { config.school.coordinates.lat = options.school.coordinates.lat; }
        if (options.school.coordinates.lng) { config.school.coordinates.lng = options.school.coordinates.lng; }

      }

    }

    // Initialize child classes
    if (typeof csdl.Menu === 'object') {
      csdl.Menu.init();
    }

    if (typeof csdl.Calendar === 'object') {
      csdl.Calendar.init();
    }

    if (typeof csdl.Slider === 'object') {
      csdl.Slider.init();
    }

    if (typeof csdl.Search === 'object') {
      csdl.Search.init();
    }

    if (typeof csdl.Form === 'object') {
      csdl.Form.init();
    }

    if (typeof csdl.Modal === 'object') {
      csdl.Modal.init();
    }

    if (typeof csdl.Map === 'object') {
      csdl.Map.init();
    }

    if (typeof csdl.Track === 'object') {
      csdl.Track.init();
    }

    if (typeof csdl.Zoom === 'object') {
      csdl.Zoom.init();
    }

    // Return success
    return true;

  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    getConfig: getConfig,
  };

})(jQuery);
