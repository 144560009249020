window.csdl = (window.csdl || {});

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
csdl.Track = (function Track($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Bind event tracking to elements that have a data-track attribute.
    $(document).on('click', '[data-track]', function() {

      var obj = $(this).data('track');

      if (typeof obj === 'object' && typeof obj.category === 'string' && typeof obj.action === 'string' && typeof obj.label === 'string') {
        sendEvent(obj.category, obj.action, obj.label);
      }

    });

    // Return success
    return true;

  };

  /**
   * Send a tracking event.
   * @public
   */
  var sendEvent = function(category, action, label) {

    if (category && action && label && csdl.App.getConfig('env') === 'production') {
      return ga('send', 'event', category, action, label);
    }

    return false;

  };

  /**
   * Send a page view event.
   * @public
   */
  var sendPage = function(url) {

    var parser;

    if (url === undefined || url === '') {
      url = location.pathname;
    } else {
      parser = document.createElement('a');
      parser.href = url;
      url = parser.pathname + parser.search;
    }

    if (csdl.App.getConfig('env') === 'production') {
      return ga('send', 'pageview', url);
    }

    return false;

  };

  // Expose public methods & properties
  return {
    init: init,
    page: sendPage,
    event: sendEvent,
  };

})(jQuery);
